.services {
  > div {
    padding-bottom: 6px;
  }

  .service-table {
    background-color: #FAFAFA;
    border-radius: 3px;
    border-spacing: 0;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .05);
    width: 100%;

    > thead > tr {
      height: 32px;

      > th {
        background-color: rgb(241, 243, 244);
        border-bottom: 1px solid #e9e9e9;
        font-weight: normal;
        padding: 12px 8px;

        &:first-child {
          border-top-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
        }
      }
    }

    > tbody {
      font-family: monospace;
      padding-top: 2px;

      > tr > td {
        height: 29px;
        padding: 4px 8px;
        white-space: nowrap;

        &:nth-of-type(2) {
          width: 100%;
        }
      }

      > tr:nth-child(odd) > td {
        background-color: #F5F5F5;
      }

      > tr:first-child > td {
        padding-top: 8px;
      }

      > tr:last-child > td {
        padding-bottom: 8px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .services > div > .service-table {
    margin: 0;
    max-width: unset;
  }
}