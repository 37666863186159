.footer {
  background-color: #f3f3f3;
  border-top: 1px solid gainsboro;
  box-sizing: border-box;
  color: #666;
  min-height: 70px;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  padding: 16px;
  width: 100%;
}

.footer a {
  color: #2f2f2f;
  text-decoration: none;

  &:hover {
    color: black;
  }
}

.footer ul {
  list-style-type: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0;
}

.footer ul li {
  float: left;
  margin-right: 12px;
}

.footer .version {
  color: #999;
  font-size: 0.8em;
  position: absolute;
  bottom: 16px;
  right: 16px;
}
