.friends-page {
  color: #2f2f2f;
}

.friends-page ul {
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
}

.friends-page ul li {
  padding: 2px;
}

.friends-page ul li:after {
  content: "";
  display: block;
  clear: both;
}

.friends-page ul li:hover {
  background-color: #F0F0F0;
}

.friends-page ul a {
  color: #2f2f2f;
  display: inline-block;
  line-height: 32px;
  text-decoration: none;
  width: 100%;
}

.friends-page ul li div {
  float: right;
  margin-top: 1px;
}

.friends-page ul img {
  float: left;
  margin-right: 6px;
  object-fit: cover;
  height: 32px;
  width: 32px;
}
