.banner {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: inline-block;
  margin: 3px 3px;
  position: relative;
}

.banner[data-editing=true]:hover .banner-tooltip {
  height: 40px;
  opacity: 1;
}

.banner-tooltip {
  background-color: rgba(0,0,0,.8);
  border-radius: 3px;
  color: white;
  position: absolute;
  left: 0; bottom: 0; right: 0;
  height: 0px;
  opacity: 0;
  transition: 0.3s;

  > div {
    padding: 8px;
    text-align: right;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
  }

  button > div > div > svg {
    fill: lightgray;
  }

  button:hover > div > div > svg {
    fill: white;
  }

  .edit-icon-wrapper,
  .edit-icon-wrapper > div > svg,
  .delete-icon-wrapper,
  .delete-icon-wrapper > div > svg {
    width: 24px;
    height: 24px;
  }
}

.banner-link {
}

.banner-link .hidden-icon-wrapper {
  display: none;
  height: 162px;
  width: 288px;
  position: absolute;
  top: 0;

  &.hidden {
    display: block;
  }

  > div {
    height: 162px;
    width: 288px;

    > svg {
      height: 100px;
      width: 288px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.banner-image {
  border-radius: 5px 5px 0 0;
  height: 162px;
  min-height: 162px;
  width: 288px;
  min-width: 288px;
  margin: 0;
  object-fit: cover;
}

.banner-info {
  /* border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  height: 199.4px; */
  box-sizing: border-box;
  display: block;
  font-family: "Roboto";
  padding: 16px;
  padding-top: 12px;
  white-space: pre-wrap;
  width: 100%;
}

.banner-info .title {
  font-weight: normal;
  margin: 0;
  margin-bottom: 16px;
}

.banner-info .username {
  margin-bottom: 6px;
}

.banner-info .username > span:first-of-type,
.banner-info .password > span:first-of-type {
  display: inline-block;
  width: 90px;
}

.banner-info .password-text {
  background-color: #303030;
  border-radius: 2px;
  color: #303030;
  padding: 0 2px;
  transition: .5s ease;
}

.banner-info .password-text:hover {
  color: white;
  transition-delay: 2s;
}

@media only screen and (max-width: 768px) {
  .banner {
    display: inherit;
    margin-bottom: 12px;
  }

  .banner-image {
    height: unset;
    width: 100%;
  }

  .banner-link {
    height: unset;
    width: unset;
    min-width: 220px;
  }
}
