.article-writer > div {
  margin-bottom: 12px;
}

.article-writer input {
  border: 1px solid gainsboro;
  box-sizing: border-box;
  padding: 0.4em;
  width: 100%;
}

.article-writer .content {
  border: 1px solid rgba(211, 213, 214, 0.5);
  min-height: 500px;
  padding: 6px;
  white-space: pre-wrap;
}

.article-writer .content ul {
  margin: 0;
  white-space: normal;
}