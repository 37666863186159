.login-page a {
  text-decoration: none;
}

.login-page > h2 {
  margin: auto;
  margin-bottom: 36px;
  width: 50%;
}

.login-page > .errors {
  margin: auto;
  width: 50%;
}

.login-page > .errors > ul {
  background-color: indianred;
  border: 1px solid darkred;
  color: whitesmoke;
  margin: 0;
  margin-bottom: 16px;
  padding: 8px 0;
  padding-left: 30px;
}

.login-page > form {
  margin-bottom: 16px;
}

.login-page > form > div {
  margin: auto;
  margin-bottom: 12px;
  width: 50%;

  @media (max-width: 939px) {
    margin: unset;
    margin-bottom: 12px;
    width: 100%;
  }
}

.login-page > form > div:last-of-type {
  margin-top: 32px;
}

.login-page > form > div > label {
  display: block;
  font-size: 1.2em;
  padding-bottom: 6px;
}

.login-page > form > div > label > a {
  float: right;
  font-size: 0.9em;
}

.login-page > form > div > input[type='email'], .login-page > form > div > input[type='password'] {
  border: 1px solid lightgray;
  box-sizing: border-box;
  font-size: 1.2em;
  padding: 6px;
  height: 48px;
  width: 100%;
}

.login-page > form > div > input[type='submit'], .login-page > form > div > a {
  display: inline-block;
  height: 40px;
  width: 50%;
}

.login-page .register-button {
  background-color: #5E6F80;
  padding: 0;
  height: 40px;
  width: 50%;
  vertical-align: top;
}

.login-page .register-button:hover {
  background-color: #455667 !important;
}

.login-page .register-button:active {
  background-color: #2B3C4D !important;
}

.login-page .register-button > a {
  display: inline-block;
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
}

.login-page .register-button > a > span {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}