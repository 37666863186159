.game-request-item {
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  display: flex;
  margin-bottom: 6px;
}

.game-request-item svg {
  fill: gray;
}

.game-request-item svg:hover {
  fill: black;
}

.game-request-item > div:first-of-type {
  background-color: lightgray;
  margin: -1px 6px -1px -1px;
  padding: 6px;
}

.game-request-item > div:last-of-type {
  padding: 6px 0;
}

.game-request-item > div > blockquote {
  border-left: 3px solid lightgray;
  margin: 10px 0;
  padding-left: 6px;
  white-space: pre;
}

.game-request-item .game-request-item-buttons button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0 6px;
  padding: 2px;
  -webkit-appearance: none;

  > div > div > svg {
    height: 16px;
    width: 16px;
  }
}

.game-request-item .avatar {
  border-radius: 30px;
  width: 32px;
}

.game-request-item a {
  color: black;
}

.game-request-item span {
  display: inline-block;
  line-height: 32px;
  margin-left: 6px;
  vertical-align: top;
}