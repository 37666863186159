.banner-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &[data-show=true] {
    display: block;
  }

  .content {
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 3px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 600px;
    max-height: 90%;
    overflow: auto;

    .top {
      display: inline-flex;
      border-bottom: 1px solid lightgray;
      margin-bottom: 12px;
      padding-bottom: 12px;
      width: 100%;

      .right {
        width: stretch;
      }
    }

    .modal-banner {
      float: left;
      width: 288px;
      height: 162px;
      min-width: 288px;
      object-fit: contain;
      margin-bottom: 6px;
      margin-right: 6px;
    }

    label {
      font-size: 0.9em;
    }

    input[type=checkbox] {
      position: relative;
      top: 2px;
      margin-left: 0;
    }

    input[type=text], input[type=url] {
      display: block;
      border: 1px solid gainsboro;
      box-sizing: border-box;
      height: 32px;
      margin-bottom: 12px;
      margin-top: 6px;
      outline: 0;
      padding: 0.1em 0.4em;
      width: 100%;

      &:focus {
        border-bottom-color: rgb(77, 144, 254);
      }
    }

    .checkbox-label {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {

  .banner-modal > .content {
    max-height: max-content;
    top: 5px; left: 5px; bottom: 5px; right: 5px;
    width: unset;
    transform: unset;
    overflow-y: auto; overflow-x: hidden;
  }

  .banner-modal > .content > .top {
    display: block;
    overflow: hidden;
  }

  .banner-modal > .content > .top > .modal-banner {
    float: unset;
    min-width: unset;
    width: 100%;
  }
}