.time-list {
  font-size: 1.1em;
  height: 126px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 50%;
}

.time-list:last-child {
  margin-bottom: 0;
}

.time-list > b {
  display: inline-block;
  margin: 8px 0;
}

.time-list > b:first-of-type {
  margin-top: 0;
}

.time-list > li {
  padding: 1px 4px;
  transition: background-color .2s;
}

.time-list > li:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

@media only screen and (max-width: 425px) {

  .time-list {
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: top;
    height: 100%;
    width: 50%;
  }

  .time-list > li {
    font-size: 3vh;
    padding: 2px 4px;
  }
}