.reports .filters {
  background-color: #f3f3f3;
  border: 1px solid lightgray;
  height: 50px;
  margin-bottom: 16px;
}

.reports .filters a {
  color: gray;
  border-right: 1px solid lightgray;
  display: inline-block;
  min-width: 80px;
  padding: 0 10px;
  line-height: 50px;
  text-decoration: none;
  text-align: center;
}

.reports .filters a:hover {
  background-color: gainsboro;
}

.reports .filters .active {
  color: #008CBA;
}

.reports .reports-list {
  background-color: #FAFAFA;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reports .report-item {
  border: 1px solid gainsboro;
  margin-top: 6px;
}

.reports .report-item:nth-of-type(odd) {
  background-color: #F5F5F5;
}

.reports .report-item .report-header {
  display: flex;
  background-color: gainsboro;
  line-height: 30px;
  padding: 10px 16px;
}

.reports .report-item .report-header a {
  color: rgb(76, 124, 173);
  font-weight: bold;
  text-decoration: none;
}

.reports .report-item .report-header img {
  border-radius: 40px;
  float: left;
  margin-right: 4px;
  width: 30px;
}

.reports .report-item .report-header span {
  white-space: pre;
}

.reports .report-item .comment {
  background-color: #E9E9EA;
  padding: 16px;
}

.reports .report-item .comment > div:first-child {
  font-size: 0.8em;
  margin-bottom: 12px;
}

.reports .report-item .comment > a {
  color: rgb(76, 124, 173);
  display: inline-block;
  margin-top: 12px;
  text-decoration: none;
}

.reports .report-item .report {
  padding: 16px;
}

.reports .report-item .report h4 {
  margin-top: 0;
}

.reports .report-item .report > div {
  margin: 4px 0;
}

.reports .report-item .report > div:first-of-type {
  font-size: 0.8em;
  margin-bottom: 12px;
}

.reports .report-item .report > a {
  color: rgb(76, 124, 173);
  display: inline-block;
  margin-top: 6px;
  text-decoration: none;
}

.reports .report-item .report > a:first-of-type {
  margin-right: 6px;
}

.reports .report-item .report > a:hover {
  color: rgb(52, 84, 117);
}