.clearmargin {
  margin: 0;
}

.ytd .description a {
  font-weight: bold;
}

.ytd .important-banner {
  background-color: dodgerblue;
  border: 1px solid royalblue;
  color: whitesmoke;
  margin-top: 16px;
  padding: 16px;
}

.ytd .important-banner > div {
  text-align: right;
}

.ytd .important-banner > div > a {
  color: whitesmoke;
  display: inline-block;
  margin-top: 12px;
  padding: 6px;
}

.ytd .important-banner > div > a:hover {
  background-color: #389dff;
}

.ytd .important-banner > div > a:active {
  background-color: #2996ff;
}

.ytd .container {
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  border-radius: 3px;
  margin-top: 1em;
  padding: 16px 12px;
}

.ytd .container.hidden {
  display: none;
}

.ytd .container > h3 {
  background-color: rgb(231, 233, 234);
  color: #555;
  margin: 0;
  padding: 18px 24px;
}

.ytd .search > h3 {
  margin: -16px -12px;
  margin-bottom: 0;
}

.ytd .search .video-link-container {
  display: flex;
  margin-top: 16px;
}

.ytd .search .video-link-container > .video-link-input {
  border: 1px solid gainsboro;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  box-sizing: border-box;
  font-size: 1.1em;
  padding: 9px 12px;
  width: 100%;
}

.ytd .search .get-button {
  border-left: none;
  border-radius: 0 3px 3px 0;
  height: 40px;
  min-width: 50px;
}

.ytd .search .get-icon {
  padding-top: 4px;
}

.ytd .search .get-icon > div > svg {
  height: 20px;
  width: 20px;
}

.ytd .player-wrapper {
  margin-top: 32px;
}

.ytd .thumbnail {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.ytd .video-thumbnail {
  display: block;
  width: 100%;
  height: 401px;
}

.ytd .embed-wrapper {
  height: 401px;
}

.ytd .embed {
  border-radius: 3px;
}

.ytd .thumbnail .video-information {
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 1.1em;
  min-height: 50px;
  padding: 10px;

  position: absolute;
  left: 0;
  bottom: -70px;
  right: 0;

  -webkit-transition: bottom .5s;
     -moz-transition: bottom .5s;
       -o-transition: bottom .5s;
          transition: bottom .5s;
}

.ytd .thumbnail .video-information[data-show=true] {
  bottom: 0px;
}

.ytd .thumbnail .video-information > div > a {
  color: white;
}

.ytd .thumbnail .video-information > div > a:hover {
  color: lightgray;
}

.ytd .thumbnail .video-information > div:first-of-type {
  margin-bottom: 6px;
}

.ytd .thumbnail .video-information > div:first-of-type > a {
  font-size: 1.15rem;
}

.ytd .thumbnail .video-information > div:last-of-type > a {
  font-size: 0.9em;
}

.ytd .thumbnail .video-information > span {
  position: absolute;
  bottom: 10px; right: 10px;
}

.ytd .loading-spinner-wrapper {
  background-color: rgba(10, 10, 10, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.ytd .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ytd .qualities > h3 {
  margin: -16px -12px;
  margin-bottom: 12px;
}

.ytd .qualities-list {
  list-style-type: none;
  margin: -16px -12px;
  padding: 0;
}

.ytd .qualities-list > li {
  padding: 12px 24px;
}

.ytd .qualities-list > li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.ytd .qualities-list > li:last-child {
  border-bottom: 0;
  margin-bottom: -16px;
}

.ytd .qualities-list > li > div:first-child > span:nth-child(1) {
  color: steelblue;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 8px;
}

.ytd .qualities-list > li > div:first-child > span:nth-child(2) {
  float: right;
  font-size: 0.8em;
  opacity: .3;
}

.ytd .qualities-list > li > div:last-child > span:first-child {
  margin-right: 12px;
}

.ytd .progress > h3 {
  margin: -16px -12px;
  margin-bottom: 0;
}

.ytd .download-progress {
  margin: 16px 0px;
  opacity: 1;
  position: relative;
}

.ytd .error {
  background-color: indianred;
  border: 1px solid red;
  color: white;
  font-family: Arial;
  font-size: 1.1em;
  height: 48px;
  line-height: 48px;
  margin-top: 1em;
  padding-left: 24px;
}

.ytd .result {
  border: 0;
  padding: 0;
}

.ytd .result > h3 {
  border: 1px solid gainsboro;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.ytd .result > video {
  background-color: lightgray;
  width: 100%;
}

.ytd .download-links {
  border: 1px solid gainsboro;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  margin-top: -4px;
  padding: 16px 12px;
}

.ytd .download-links > div {
  background-color: #008CBA;
  border: 1px solid gainsboro;
  border-radius: 3px;
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
}

.ytd .download-links > div[disabled] {
  background-color: lightgray;
}

.ytd .download-links .download-button {
  background-color: #008CBA;
  color: white;
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.ytd .download-links .download-button[disabled] {
  background-color: lightgray;
  color: gray;
  pointer-events: none;
}

.ytd .download-links .download-icon-wrapper {
  display: inline-block;
}

.ytd .download-links .download-icon-wrapper > div > svg {
  fill: currentColor;
  padding: 6px;
  width: 32px;
  /* enable-background:new 0 0 479 479; */
}

.ytd .download-links .download-button > span {
  font-size: 1.2em;
  line-height: 47px;
  padding-left: 8px;
  vertical-align: top;
}

.ytd .download-links .download-button .cached-label {
  float: right;
  margin-right: 16px;
  opacity: 1;
}

@media only screen and (max-width: 768px) {

  .ytd .information {
    margin-left: 0px;
  }

  .ytd .video-request {
    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }

  .ytd > .video-request > .video-link-input {
    margin-top: 2px;
    padding: 14px 16px 12px;
    max-width: unset;
  }
}
