.status-label {
  color: white;
  border-radius: 3px;
  display: inline-block;
  font-family: monospace;
  min-width: 40px;
  padding: 2px 4px;
  text-align: center;

  &[color="green"] {
    background-color: #47D0AA;
  }

  &[color="red"] {
    background-color: #F77A6A;
  }

  &[color="gray"] {
    background-color: rgb(128, 128, 128);
  }
}