.progress-item {
  height: 64px;
  padding: 6px;
  position: relative;
}

.progress-item:nth-of-type(odd) {
  background-color: #FAFAFA;
}

.progress-item:hover {
  background-color: gainsboro;
}

.progress-item > a {
  color: rgb(76, 124, 173);
  font-weight: bold;
  text-decoration: none;
}

.progress-item .yt-link > img {
  width: 16px;
  margin-right: 8px;
}

.progress-item > button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 6px;
}

.progress-item .progress-bar {
  display: block;
}

.progress-item > div {
  display: block;
  position: relative;
  margin-top: 24px;
}

.progress-item > div > span {
  color: white;
  text-shadow: 0px 0px 10px darkslategrey;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
