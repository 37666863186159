.volume-bar {
  input {
    margin:  2px 6px;
  }

  p {
    margin-bottom: 2px;
    margin-top: 0;
  }

  span {
    display: inline-block;
    vertical-align: top;
    font-size: 1.5em;
    user-select: none;
  }
}