.music-card {
  position: relative;
}

.music-card > .music-warning {
  background-color: darkred;
  border: 1px solid black;
  color: white;
  padding: 20px;
}

.music-card > .now-playing {
  font-size: 1.4em;
  margin-bottom: 16px;
  overflow: hidden;
}

.music-card > .now-playing > span:first-child {
  font-weight: bold;
}

.music-card > .now-playing > span:last-child {
  float: right;
}

.music-card > .music-player {
  width: 100%;
}

.music-card > .playing-options {
  border-bottom: 1px solid lightgray;
  margin-bottom: 16px;
  margin-top: 8px;
  padding-bottom: 16px;
}

.music-card > .playing-options > span > button {
  margin-right: 6px;
}

.music-card > .playing-options > label {
  margin-right: 6px;
}

.music-card > .playing-options > label > input,
.music-card > .playing-options > div > label > input {
  position: relative;
  top: 2px;
}

.music-card > .playing-options > .right-option {
  float: right;
  margin-right: 0;
}

.music-card > .playing-options .information-icon:hover  {
  stroke: white !important;
}

.music-card > .playing-options .information-icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}

.music-card > .playing-options .information-icon > div {
  height: 100%;
}

.tooltip {
  display: inline-block;
  margin-left: 6px;
  position: relative;
}

.tooltip:hover > .information-icon > div > svg > g > circle:nth-of-type(2) {
  fill: black !important;
}

.tooltip:hover > .information-icon > div > svg > g > circle:last-of-type {
  fill: white !important;
}

.tooltip:hover > .information-icon > div > svg > g > path {
  stroke: white !important;
}

.tooltip:hover > .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  right: 0;
  top: 100%;
  margin-top: 1px;
}

.music-list {
  background-color: #FAFAFA;
  border-radius: 3px;
  color: #666;
  width: 100%;
}

.music-list > thead > tr {
  height: 32px;
}

.music-list > thead > tr > th {
  background-color: rgb(241, 243, 244);
  border-bottom: 1px solid rgba(211, 213, 214, 1);
  cursor: pointer;
  font-weight: normal;
  padding: 12px 8px;
}

.music-list > thead > tr > th:hover {
  background-color: rgb(213, 219, 222);
}

.music-list > thead > tr > th:nth-child(1) {
  border-top-left-radius: 3px;
  width: 37%;
}

.music-list > thead > tr > th:nth-child(2) {
  width: 37%;
}

.music-list > thead > tr > th:nth-child(3) {
  width: 25%;
}

.music-list > thead > tr > th:nth-child(4) {
  border-top-right-radius: 3px;
  min-width: 76px;
}

.music-list > tbody > tr:nth-child(odd) {
  background-color: #F5F5F5;
}

.music-list > tbody > tr:hover {
  background-color: #e0e0e0;
}

.music-list > tbody > tr > td {
  padding: 0;
}

.music-list > tbody > tr > td > a {
  color: black;
  box-sizing: border-box;
  display: inline-block;
  padding: 7px 11px;
  text-decoration: none;
  width: 100%;
}

.music-list > tbody > tr > td > a.playing {
  color: #008CBA;
}