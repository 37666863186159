.home {
  position: relative;
} 

.home .admin-tools {
  text-align: right;
  position: absolute;
  top: 28px;
  right: 28px;
}

.home > ul {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 939px) {
    & {
      margin: 0 auto;
    }
  }
}

.edit-button {
  margin: 0 3px 3px 3px;
}

.add-new-banner {
  height: 100px;
  text-align: center;
  margin-top: 32px;
  transition: .5s;

  &[data-hide=true] {
    height: 0px;
    margin-top: 0px;
    opacity: 0;
  }

  .add-new-banner-button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    height: 100px;
    width: 100px;
    outline: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;

    &[data-hide=true] {
      cursor: default;
      pointer-events: none;
    }

    .add-new-banner-icon {
      height: 100px;
      width: 100px;

      > div {
        height: 100px;
        width: 100px;

        > svg {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}
