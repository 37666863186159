.settings .error {  
  outline: red auto 5px;
}

.settings .row {
  margin: 1em 0;
}

.settings .row > .left-text {
  display: inline-block;
  font-size: 0.9em;
  padding-right: 12px;
  text-align: right;
  width: 110px;
}

.settings .row > .error-message {
  color: red;
  display: block;
  font-size: 0.9em;
  margin-top: 6px;
  margin-left: 122px;
}

.settings .row > .error-message[data-hidden="true"] {
  display: none;
}

.settings > div:first-of-type {
  margin-bottom: 32px;
}

.settings > form > div:last-of-type {
  margin-top: 8px;
}

.settings input[type="email"], .settings input[type="password"] {
  border: 1px solid gainsboro;
  background-color: #f7f7f8;
  min-width: 200px;
  padding: 0.4em;
}

.settings .privacy {
  display: flex;
  font-size: 0.9em;
}

.settings .privacy > span:first-of-type {
  display: inline-block;
  padding-right: 12px;
  padding-top: 6px;
  text-align: right;
  width: 110px;
}

.settings .privacy .settings-list > div {
  margin-bottom: 6px;
}

.settings .privacy .settings-list > div > span {
  display: inline-block;
  width: 170px;
}

.settings .privacy .settings-list > div > select {
  border: 1px solid gainsboro;
  background-color: #f7f7f8;
  padding: 0.3em;
}