.search .description {
  margin-bottom: 32px;
}

.search .search-form, .search-form {
  display: inline-block;
  width: 100%;
}

.search .search-form input[type="text"], .search .search-form textarea {
  box-sizing: border-box;
  font-size: 1em;
  padding: 10px;
  width: calc(100% - 100px);
  -webkit-appearance: textarea;
}

.search .search-form input[type="submit"] {
  box-sizing: border-box;
  font-size: 1em;
  padding: 12px;
}

.search .error-message {
  background-color: indianred;
  border: 1px solid darkred;
  color: whitesmoke;
  margin: 8px 0;
  padding: 12px 8px;
}

.search .search-result {
  border-top: 1px solid #c5c5c5;
  list-style-type: none;
  margin: 0;
  margin-top: 16px;
  padding: 0;
  padding-top: 16px;
}

.search ul li {
  height: 50px;
  margin-bottom: 6px;
}

.search ul li:hover {
  background-color: gainsboro;
}

.search ul li a span {
  display: inline-block;
  height: 100%;
  line-height: 50px;
  margin-left: 6px;
  vertical-align: top;
  width: calc(100% - 56px);
}

.search .avatar {
  border: 1px solid #008CBA;
  object-fit: cover;
  width: 48px;
  height: 48px;
}
