.edit-profile {

}

.edit-profile .clear-button {
  margin-left: 16px;
}

.edit-profile > div .row {
  margin: 1em 0;
}

.edit-profile > div .row.bio-row > span {
  vertical-align: top;
}

.edit-profile > div .row > span {
  display: inline-block;
  font-size: 0.9em;
  padding-right: 12px;
  text-align: right;
  width: 80px;
}

.edit-profile input[type="file"] {
  display: block;
  margin-bottom: 12px;
  margin-top: 6px;
}

.edit-profile textarea {
  font-family: Arial;
  min-height: 120px;
  resize: vertical;
  width: 70%;
}

.edit-profile input[type="text"], .edit-profile textarea {
  border: 1px solid gainsboro;
  background-color: #f7f7f8;
  padding: 0.4em;
}

.edit-profile > div > button {
  margin-left: 92px;
  margin-top: 6px;
}

.edit-profile .avatar-container {
  border-top: 1px solid lightgray;
  padding: 1em 0;
  margin-top: 1em;
}

.edit-profile .avatar-container > h3 {
  margin-top: 0;
}

.edit-profile .avatar-container > img {
  display: block;
}

.edit-profile .avatar-container .avatar-variations {
  display: flex;
  font-size: 0.8em;
  align-items: baseline;
}

.edit-profile .avatar-container .avatar-variations > div {
  margin-right: 6px;
}

.edit-profile .avatar-container .avatar-variations img {
  border: 1px solid #008CBA;
}

.edit-profile .avatar-container .avatar-upload {
  display: inline-flex;
  background: #eee;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 4px 10px;
  margin-top: 6px;
}

.edit-profile .avatar-container .avatar-upload > div {
  align-self: center;
  margin-right: 6px;
}

.edit-profile .avatar-container .avatar-upload > input {
  margin: 6px;
}

.edit-profile .avatar-container .avatar-upload > button {
  align-self: center;
}

.edit-profile .background img {
  width: 100%;
}