.dialer {
  border-collapse: collapse;
  display: inline-block;
  font-size: 3em;
  grid-area: digits;
  vertical-align: top;
  width: 260px;

  > tbody > tr > td {
    border: 1px solid lightgray;
    box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
    box-sizing: border-box;
    padding: 10px;
    width: 80px;
    height: 80px;
    user-select: none;

    /* New */
    display: inline-block;
    border-radius: 50px;
    line-height: 58px;
    margin: 3px;
    transition: background-color .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
            user-select: none;

    > span {
      border-bottom: 2px solid white;
      pointer-events: none;
    }

    @media only screen {
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
      }
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.9);
    }

    &[data-digit="⌫"],
    &[data-digit="C"] {
      font-size: 0.8em;

      > span {
        border-bottom: none;
      }
    }
  }
}