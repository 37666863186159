.checkbox-container {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-container .checkbox-mark {
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  transition: background-color 0.10s linear;
}

.checkbox-container:hover input ~ .checkbox-mark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkbox-mark {
  background-color: #008CBA;
}

.checkbox-container .checkbox-mark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkbox-mark:after {
  display: block;
}

.checkbox-container .checkbox-mark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
