.article > h1 {
  background-color: #EDEDED;
  border-bottom: 1px solid rgba(211, 213, 214, 0.5);
  color: #444;
  margin: -1em -1em 36px -1em;
  padding: 1em;
}

.article .content {
  white-space: pre-wrap;
}

.article .article-footer {
  background-color: #EDEDED;
  border-top: 1px solid rgba(211, 213, 214, 0.5);
  padding: 28px;
  margin: 32px -2em -2.5em -2em;
}