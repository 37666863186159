.game-requests .clickable-header {
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  font-size: 1.3em;
  margin-top: 1em;
  padding: 16px 12px;
}

.game-requests .clickable-header:disabled {
  pointer-events: none;
}

.game-requests .clickable-header:not([disabled]):hover {
  background-color: lightgrey;
  cursor: pointer;
}

.game-requests .clickable-header > span:last-of-type {
  float: right;
  transition: .3s;
}

.game-requests .clickable-header > span:last-of-type[data-open=true] {
  transform: rotateX(180deg);
}

.game-requests-form {
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  border-top: 0;
  margin-bottom: 32px;
  padding: 16px 12px;
  transition: 0.3s;
  overflow: hidden;

  > p:first-of-type {
    margin-top: 0;
  }
}

.game-requests-form[data-open=false] {
  border-bottom: 0;
  height: 0px;
  margin-bottom: 0px;
  padding-bottom: 0;
  padding-top: 0;
}

.game-requests-form .errors > ul {
  background-color: indianred;
  border: 1px solid darkred;
  color: whitesmoke;
  margin: 0;
  margin-bottom: 16px;
  padding: 8px 0;
  padding-left: 30px;
}

.game-requests-form p {
  margin-bottom: 16px;
}

.game-requests-form input[type="text"], .game-requests-form textarea {
  box-sizing: border-box;
  font-size: 1em;
  padding: 16px 16px 12px;
  width: 100%;
  -webkit-appearance: textarea;
}

.game-requests-form input[type="submit"] {
  box-sizing: border-box;
  font-size: 1em;
  padding: 16px 16px 12px;
  width: 100%;
}

.game-requests-form textarea {
  font-family: Arial;
  height: 100px;
  resize: vertical;
}

.game-requests > .requests-wrapper {
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  margin-top: 1em;
  padding: 16px 12px;

  > h3 {
    margin-top: 0;
  }
}

.game-requests > hr {
  margin: 32px 0;
}

.submit-status {
  display: block;
  font-size: 1.3em;
  margin: 16px 0;
  opacity: 0.0;
  transition: opacity .5s ease;
}

.submit-status.animation {
  opacity: 1.0;
}

.game-requests-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
