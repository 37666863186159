.article-editor { 
  width: 100%;
  min-height: 200px;
  position: relative;
}

.article-editor .toolbar {
  background-color: #FAFAFA;
  border: 1px solid rgba(211, 213, 214, 0.5);
}

.article-editor .toolbar button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  height: 28px;
  width: 28px;

  &:focus {
    border: 1px solid rgba(211, 213, 214, 0.5);
    outline: 0;
  }

  &:hover {
    background-color: #EAEAEA;
  }
}

.article-editor textarea {
  border: 1px solid rgba(211, 213, 214, 0.5);
  border-top: 0;
  box-sizing: border-box;
  padding: 6px;
  resize: vertical;
  height: 500px;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

.article-editor .modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000021;
}

.article-editor .modal {
  background-color: #fafafa;
  border: 1px solid #e6e7e7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 180px;
  width: 400px;
}

.article-editor .modal .modal-top {
  background-color: #008cba;
  color: rgb(243, 243, 243);
  font-size: 1.2em;
  height: 20px;
  line-height: 20px;
  padding: 12px;
}

.article-editor .modal .modal-content {
  padding: 12px;
}

.article-editor .modal .modal-content input {
  border: 1px solid lightgray;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 6px;
  height: 32px;
  width: 100%;
}

.article-editor .modal .modal-content input:focus {
  border: 1px solid #008CBA;
  outline: 0;
}

.article-editor .modal .modal-bottom button {
  margin-left: 6px;
  min-width: 80px;
}

.article-editor .modal .modal-bottom button:last-of-type {
  background-color: #666;
}

.article-editor .modal .modal-bottom {
  position: absolute;
  bottom: 12px;
  right: 12px;
}