.maintenance {

}

.maintenance ul {
  list-style-type: none;
  padding-left: 0;
}

.maintenance ul li {
  /* background-color: rgb(0, 191, 255); */
  background-color: #f3f3f3;
  border: 1px solid gainsboro;
  border-radius: 3px;
  margin-top: 8px;
  padding: 16px;
}

.maintenance ul li:first-of-type {
  margin-top: 0;
}

.maintenance ul li > div > span:last-of-type {
  color: gray;
  float: right;
}