.timer {
  display: flex;
  font-family: 'Roboto Mono', monospace;
  font-size: 6em;
  margin-bottom: 16px;
  padding: 0.2em;

  > span {
    width: 175px;
  }

  > span > div:first-of-type > span {
    font-size: 0;
  }

  > span > div:last-of-type {
    font-size: 0.4em;
    user-select: none;
  }
}
