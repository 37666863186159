.clearleft {
  clear: left;
}

.user-profile {
  padding: 0;
}

.user-profile .profile-header {
  background-color: #f3f3f3;
  padding: 2em;
}

.user-profile .avatar {
  border: 1px solid #008CBA;
  float: left;
  margin-right: 16px;
  object-fit: cover;
  height: 184px;
  width: 184px;
}

.user-profile .display-name {
  font-weight: normal;
}

.user-profile .real-name {
  
}

.user-profile .location {
  
}

.user-profile .menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.user-profile .menu ul {
  list-style: none;
  padding: 0;
}

.user-profile .menu button {
  background: none;
  border: none;
  box-sizing: content-box;
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  text-align: left;
}

.user-profile .menu a, .user-profile .menu button {
  color: #666;
  display: inline-block;
  padding: 6px;
  text-decoration: none;
  width: 100%;
}

.user-profile .menu a:hover, .user-profile .menu button:hover {
  background-color: #d1d1d1;
  cursor: pointer;
}

.user-profile .menu button:focus {
  outline: 0;
}

.user-profile .profile-body {
  display: flex;
}

.user-profile .profile-bio {
  padding: 2em;
  width: 100%;
}

.user-profile .profile-bio a {
  color: #369;
  text-decoration: none;
}

.user-profile .profile-bio h4 {
  margin-top: 0;
}

.user-profile .bio {
  white-space: pre-wrap;
}

.user-profile .links {
  background-color: slategray;
  color: #F0F0F0;
  font-size: 0.9em;
  padding: 16px;
  width: 280px;
}

.user-profile .links h3 {
  margin-top: 0;
}

.user-profile .links .link-row {
  margin-bottom: 8px;
  position: relative;
}

.user-profile .links .link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.user-profile .links .link-img {
  float: left;
  user-select: none;
  width: 32px;
}

.user-profile .links .link-title {
  line-height: 32px;
  margin-left: 6px;
}

.user-profile .links .friends-header {
  display: flex;
  margin-top: 32px;
  margin-bottom: 14px;
}

.user-profile .links .friends-header h3 {
  margin: 0;
  width: 100%;
}

.user-profile .links .friends-header h3 > div {
  color: #D0D0D0;
  font-size: 0.8em;
}

.user-profile .links .friends-header a {
  color: lightsteelblue;
  width: 60px;
  text-decoration: none;
}

.user-profile .links .friends-header a:visited {
  color: lightsteelblue;
}

.user-profile .links .friends-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.user-profile .links .friends-list li {
  padding: 4px;
}

.user-profile .links .friends-list li:hover {
  background: lightslategrey;
}

.user-profile .links .friends-list a {
  color: #F0F0F0;
  display: inline-block;
  line-height: 32px;
  text-decoration: none;
  width: 100%;
}

.user-profile .links .friends-list a img {
  float: left;
  margin-right: 6px;
  object-fit: cover;
  height: 32px;
  width: 32px;
}