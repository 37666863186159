.progress {
  /* Imitate a <hr> element */
  border-top: 1px solid #e6e9e9;
  margin-top: 20px;
  padding-top: 20px;
}

.progress > h2:after {
  color: red;
  content: "Admin tools";
  font-size: small;
  margin-left: 12px;
}

.progress > ul {
  background-color: #f3f3f3;
  border: 1px solid #ececec;
  border-radius: 3px;
  list-style: none;
  padding: 0;
}