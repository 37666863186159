.card {
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #ffffff;
  margin: 32px auto;
  max-width: 768px;
  padding: 2em 2em 2.5em;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);

  &[big] {
    max-width: 940px;

    @media (max-width: 939px) {
      max-width: 768px;
    }
  }

  &[wide] {
    max-width: 1200px;
  }
}

.card h1 {
  margin: 0;
  margin-bottom: 36px;
}

@media only screen and (max-width: 768px) {
  .card {
    margin: 0 auto;
    padding: 1.5em;
    overflow: hidden;
  }

  .card h1 {
    margin-bottom: 18px;
  }
}