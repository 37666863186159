html {
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  position: relative;
}

body {
  color: #2f2f2f;
  background-color: #FAFAFA;
  background-position: center top;
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin: 0;
}

body[data-modalopen] {
  overflow: hidden;
}

:root {
  --main-button-color: #008CBA;
}

a {
  text-decoration: none;
}

a[href] {
  color: #008CBA;
}

button:not([data-nostyling]),
input:not([data-nostyling])[type='submit'] {
  background-color: var(--main-button-color);
  border: 1px solid gainsboro;
  border-radius: 3px;
  color: rgb(243, 243, 243);
  min-width: 100px;
  padding: 6px 10px;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;

  &:disabled {
    background-color: darkgray;
    color: rgb(222, 222, 222);
  }

  &:focus {
    outline: 1;
  }

  &:hover:enabled {
    background-color: #00779e;
    cursor: pointer;
  }

  &:active:enabled {
    background-color: #006080;
  }
}

.contentWrapper:after {
  content: "";
  display: block;
  height: 78px;
}

@media only screen and (max-width: 768px) {
  .contentWrapper:after {
    height: 111px;
  }
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto-Regular.ttf") format("truetype");
}
