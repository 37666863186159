.service-page {
  > h3 {
    background-color: rgb(241, 243, 244);
    border: 1px solid #e9e9e9;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #666;
    margin-bottom: 0;
    padding: 0.83em;
  }

  .auto-refresh {
    position: absolute !important;
    right: 0.83em;
    top: 0.76em;

    > .auto-refresh-label {
      display: inline-block;
      font-size: 0.8em;
      font-weight: normal;
      margin-right: 6px;
      line-height: 20px;
      vertical-align: top;
    }
  }

  .status {
    display: inline-block;

    > span {
      margin-left: 6px;
    }
  }

  .error {
    background-color: indianred;
    border: 1px solid red;
    border-radius: 3px;
    color: white;
    margin-top: 16px;
    margin-bottom: 4px;
    padding: 16px;
  }

  .status-container {
    background-color: #FAFAFA;
    border: 1px solid #e9e9e9;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow:  0 1px 1px rgba(0,0,0,.05);
    padding: 8px;
  }

  .commands {
    background-color: #FAFAFA;
    border: 1px solid #e9e9e9;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow:  0 1px 1px rgba(0,0,0,.05);
    padding: 8px;
  }

  .log {
    background-color: #FAFAFA;
    border: 1px solid #e9e9e9;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow:  0 1px 1px rgba(0,0,0,.05);
    box-sizing: border-box;
    font-size: .85em;
    resize: none;
    height: 500px;
    width: 100%;
    white-space: pre;
  }
}
