.comments-container {
  padding: 2em;
}

.comments-container .comment-reply, .comments-container .comment-edit {
  border: 1px solid gainsboro;
  background-color: #f7f7f8;
  font-family: Arial;
  font-size: 1em;
  margin-bottom: 1em;
  min-height: 100px;
  padding: 0.4em;
  width: 99%;
  resize: none;
}

.comments-container .comment-edit {
  background-color: white;
  margin: 8px 0;
  margin-bottom: 0;
  width: 600px;
}

.comments-container .comment-edit-wrapper {
  display: inline-block;
  margin-bottom: 8px;
  width: 100%;
}

.comments-container .comment-edit-wrapper a {
  color: #666;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
}

.comments-container .comment-edit-wrapper a:last-of-type {
  margin-left: 6px;
}

.comments-container .comment-edit-wrapper a:hover {
  color: black;
}

.comments-container .comments-list {
  list-style: none;
  padding: 0;
}

.comments-container .comments-list .comment {
  background-color: #f7f7f8;
  border: 1px solid gainsboro;
  margin-bottom: 6px;
  padding: .4em;
}

.comments-container .comments-list .comment[data-highlight="true"] {
  background-color: lightyellow;
}

.comments-container .comments-list .comment .comment-avatar {
  margin-right: 16px;
}

.comments-container .comments-list .comment .comment-avatar > a > img {
  border: 1px solid #008CBA;
  object-fit: cover;
  width: 48px;
  height: 48px;
}

.comments-container .comments-list .comment .comment-commenter {
  color: #369;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
}

.comments-container .comments-list .comment .comment-commenter:not([href]) {
  color: #666;
}

.comments-container .comments-list .comment .comment-date,
.comments-container .comments-list .comment .comment-id {
  color: gray;
  font-size: 0.8em;
  margin-left: 6px;
}

.comments-container .comments-list .comment .comment-text {
  margin: 8px 0;
  white-space: pre-line;
}

.comments-container .comments-list .comment .comment-buttons {
  position: absolute;
  top: -2px;
  right: -2px;
  opacity: 0;
}

.comments-container .comments-list .comment .comment-buttons button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 4px 6px;
}

.comments-container .comments-list .comment .comment-buttons button > div > div > svg {
  fill: #666;
  height: 18px;
  width: 18px;
}

.comments-container .comments-list .comment .comment-buttons button:hover > div > div > svg {
  fill: red;
}

.comments-container .comments-list .comment:hover .comment-buttons {
  opacity: 1;
}

.comments-container .buttons-container > span {
  cursor: pointer;
  font-size: 15px;
  margin: 0px 2px;
}

.comments-container .buttons-container > span[disabled] {
  color: lightgray;
  cursor: default;
}

.comments-container .buttons-container > .arrow {
  background-color: slategray;
  border-radius: 4px;
  color: white;
  display: inline-block;
  padding: 2px 12px;
}

.comments-container .buttons-container > .page-button {
  display: inline-block;
  vertical-align: middle;
}