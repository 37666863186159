.header {
  background-color: #f3f3f3;
  border-bottom: 1px solid gainsboro;
  height: 52px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header div > div {
  float: left;
  height: 44px;
}

.header div img {
  cursor: pointer;
  display: inline-block;
  padding: 6px 12px;
}

.header div img:hover:not(.active) {
  background-color: #ddd;
}

.header-menu {
  display: none;
  float: right;
}

.header .search-divider {
  border-right: 1px solid #c5c5c5;
  margin: 16px 0 16px 0;
}

.header-user {
  display: flex;
  float: right;
  position: relative;
}

@media only screen and (max-width: 768px) {

  .header {
    overflow: visible;
  }

  .header ul {
    margin-top: -3px;
  }

  .header-menu {
    display: flex;
    height: 100%;
  }

  .header-list {
    display: none;
  }

  .header-list.open {
    background-color: lightgray;
    display: inline-block;
    position: relative;
    z-index: 9999;
    width: 100%;
  }

  .header-user {
    float: unset;
  }

  .header-user a {
    width: unset !important;
  }

  .header-user .logout-button {
    position: absolute;
    right: 0;
  }
}