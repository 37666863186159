.popup-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 1000;
}

.popup-container[data-show=true] {
  display: unset;
}

.popup-container > div {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  min-width: 500px;
  opacity: 1;
}

.popup-container > div > textarea {
  box-sizing: border-box;
  display: block;
  height: 100px;
  width: 100%;
  margin: 16px 0;
  resize: none;
}

.popup-container > div > div:last-child {
  display: block;
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}