.header-item {
  float: left;
  height: 44px;
  overflow: hidden;
}

.header a {
  color: #666;
  border-bottom: 3px solid transparent;
  display: inline-block;
  font-size: 1.1em;
  text-align: center;
  margin: 15px 16px;
  padding: 2px;
  text-decoration: none;
}

.header a:hover:not(.active) {
  border-bottom: 3px solid gray;
}

.header a.active {
  color: #008CBA;
  border-bottom: 3px solid #008CBA;
}

@media only screen and (max-width: 768px) {
  .header-item, .header a {
    box-sizing: border-box;
    display: block;
    height: unset;
    float: unset;
    text-align: left;
  }

  .header-item {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .header-item > a {
    margin-bottom: 0;
    margin-top: 0;
    padding: 15px 0;
  }
}