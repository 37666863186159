.articles ul {
  list-style: none;
  padding-left: 0;
}

.articles ul li {
  background-color: #FAFAFA;
  border: 1px solid rgba(211, 213, 214, 0.5);
  margin-bottom: -1px;
  padding: 12px;
}

.articles ul li:hover {
  background-color: #EAEAEA;
}

.articles ul li:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.articles ul li:last-of-type {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.articles ul li > a {
  display: block;
  margin-bottom: 6px;
}
