.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.countdown {
  > .outer-timer {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: table;
    margin: 32px auto;
    padding: 32px;
    text-align: center;

    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    @media only screen and (max-width: 672px) {
      display: block;
      border-radius: 0;
      box-shadow: 0;
      margin-top: 0;
      padding: 16px;
      width: stretch;
    }

    > div > .times {
      display: flex;
      grid-area: times;
      padding: 0 16px;
    }
  }
}

.countdown > .outer-timer > .countdown-grid {
  display: grid;
  margin: 0 auto;
  padding: 32px 0;
  padding-top: 0;
  width: 520px;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    "digits times"
    "digits volume"
    "digits buttons";
  grid-gap: 1px;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns:         min-content;
}

.countdown .buttons .volume-bar {
  padding-bottom: 16px;
}

.countdown .buttons {
  grid-area: buttons;
}

.countdown .buttons > a {
  border: 1px solid lightgray;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-size: 1.1em;
  padding: 6px;
  text-decoration: none;
  width: 90px;
  transition: background-color .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:first-of-type {
    margin-right: 16px;
  }

  &[disabled] {
    border: 1px solid gray !important;
    color: gray;
    pointer-events: none;
  }

  &.green {
    border: 1px solid lightgreen !important;
  }

  &.red {
    border: 1px solid red !important;
  }
}

@media only screen and (max-width: 672px) {

  .countdown > .outer-timer > .timer {
    font-size: 14vw;
  }

  .countdown > .outer-timer > .countdown-grid {
    width: unset;
    grid-template-areas:
      "digits digits"
      "buttons buttons"
      "times times";
    grid-row-gap: 12px;
  }

  .countdown .dialer {
    margin: 0 auto;
  }

  .countdown .buttons {
    font-size: 1.3em;
    margin: 0 auto;

    > a {
      display: block;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 12px;
      width: 241px;
    }

    > a:first-of-type {
      margin-bottom: 6px;
      margin-right: 0;
    }
  }

  .countdown > .outer-timer > div > .times {
    display: flex;
    padding-top: 12px;
    margin: 0 auto;
  }
}