.stats-jump-dialog {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(211, 213, 214, 1);
  border-radius: 4px;
  position: absolute;
  display: block;
  padding: 6px;
  margin-top: 2px;
  height: 60px;
  width: 200px;
}

.stats {
  border: 1px solid rgba(211, 213, 214, 1);
}

.entries {
  background-color: rgb(241, 243, 244);
  border: 1px solid rgba(211, 213, 214, 1);
  padding-left: 0;
  padding-right: 0;
}

.entries > h1 {
  padding-left: 2em;
  padding-right: 2em;
}

.entries .failed-to-fetch {
  background-color: indianred;
  border: 1px solid darkred;
  color: whitesmoke;
  font-size: 1.2em;
  margin: 16px;
  padding: 16px;
}

.entries .pagination {
  background-color: rgb(241, 243, 244);
  border-bottom: 1px solid rgba(211, 213, 214, 1);
  padding: 4px;
}

.entries .pagination-page {
  background-color: rgb(241, 243, 244);
  border: 1px solid rgba(211, 213, 214, 1);
  font-size: 0.8em;

  display: inline-block;
  line-height: 26px;
  margin-right: -1px;
  padding: 4px;
  height: 26px;
  width: 26px;

  cursor: pointer;
  text-align: center;
  user-select: none;

  &[disabled] {
    background-color: lightgray;
    color: darkslategray;
    cursor: default;
  }

  &:not([disabled]):hover {
    background-color: #899eb3;
  }
}

.entries .pagination > span:nth-of-type(2) > .pagination-page {
  width: 36px;
}

.entries .stats-table {
  background-color: #FAFAFA;
  border-radius: 3px;
  color: #666;
  width: 100%;
}

.entries .stats-table > thead > tr {
  height: 32px;
}

.entries .stats-table > thead > tr > th {
  background-color: rgb(241, 243, 244);
  border-bottom: 1px solid rgba(211, 213, 214, 1);
  cursor: pointer;
  font-weight: normal;
  padding: 12px 8px;
}

.entries .stats-table > tbody {
  font-size: 0.9em;
}

.entries .stats-table > tbody > tr:hover {
  background-color: #e0e0e0 !important;
}

.entries .stats-table > tbody > tr > td {
  padding: 4px;
  padding-left: 7px;
}

.entries .stats-table > tbody > tr:nth-child(odd) {
  background-color: #F5F5F5;
}

.entries .stats-table > tbody > tr > .method > span {
  background-color: gray;
  color: white;
  border-radius: 3px;
  display: inline-block;
  font-family: monospace;
  min-width: 40px;
  padding: 2px 4px;
  text-align: center;
}

.entries .stats-table > tbody > tr > .method > span[data-method=get] {
  background-color: dodgerblue;
}

.entries .stats-table > tbody > tr > .method > span[data-method=head] {
  background-color: darkred;
}

.entries .stats-table > tbody > tr > .method > span[data-method=post] {
  background-color: green;
}

.entries .stats-table > tbody > tr > .ip {
  white-space: nowrap;
}

.entries .stats-table > tbody > tr > .ip img {
  padding-right: 6px;
  padding-top: 2px;
}

.entries .stats-table > tbody > tr > .ip > div {
  display: inline-block;
  vertical-align: text-top;
}

.entries .stats-table > tbody > tr > .ip > div > div {
  max-width: 150px;
  padding-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.entries .stats-table > tbody > tr > .url > div:nth-child(1) {
  font-weight: bold;
}

.entries .stats-table > tbody > tr > .url > div:nth-child(2) {
  padding-top: 3px;
}