@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.plex .queue-list-actions {
  background-color: #EDEDED;
  margin-top: 16px;
  text-align: right;
}

.plex .queue-list-actions > .action {
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  opacity: 0.8;
}

.plex .queue-list-actions > .action:hover {
  background-color: #E2E2E2;
  opacity: 1;
}

.plex .queue-list-actions > .action > i {
  display: inline-block;
  height: 16px;
  padding-right: 6px;
  vertical-align: middle;
}

.plex .queue-list-actions > .action > i div {
  display: inline-block;
}

.plex .queue-list-actions > .action > i svg {
  height: 14px;
  width: 14px;
}

.plex .queue-list-actions > .action[data-loop="true"] > i svg {
  -webkit-animation: rotating 2s linear infinite;
     -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
       -o-animation: rotating 2s linear infinite;
          animation: rotating 2s linear infinite;
}

.plex .queue-list-actions > .action > span {
}

.plex .queue-list {
  background-color: #FAFAFA;
  list-style: none;
  margin: 0;
  padding: 0;
}

.plex .queue-list > li {
}

.plex .queue-list > li:nth-child(odd) {
  background-color: #F5F5F5;
}

.plex .queue-list > li:hover {
  background-color: #EAEAEA;
}

.plex .queue-list > li > .top {
  position: relative;
  min-height: 60px;
  padding: 12px;
}

.plex .queue-list > li > .top > div > span {
  display: inline-block;
  margin-right: 4px;
  width: 16px;
}

.plex .queue-list > li > .top > div > a {
  display: inline-block;
  padding-bottom: 22px;
  width: calc(100% - 20px);
}

.plex .queue-list > li > .top > div > label {
  line-height: 21px;
}

.plex .queue-list > li > .top > button,
.plex .queue-list > li > .bottom > button {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.plex .queue-list > li > .top video {
  margin-bottom: 16px;
  width: 100%;
}

@keyframes heightTo100 {
  0% { height: 0px; }
  100% { height: 100px; }
}

.plex .queue-list > li > .bottom {
  animation: heightTo100 0.25s ease-out;
  background-color: #e2e2e2;
  position: relative;
  overflow: hidden;
  height: 100px;
  padding: 12px;
}

/* .plex .queue-list > li > .bottom[data-is-open=true] {
  height: 100px;
  padding: 12px;
} */